<template>
  <div class="level_item" :class="{ 'level_item_active': isActiveNo === accNumber }" @click="chooseHandler">
    <div class="level_top">
      <span>{{ $t('multiib.level', { level: level + 1 }) }}</span>
      <span>{{ accNumber }}</span>
    </div>
    <div class="level_bottom">
      <span>
        <svg-icon className="level_icon" iconClass="level"></svg-icon>
      </span>
      <p class="account_name">{{ name }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ibCard',
  props: {
    name: String,
    accNumber: Number,
    isActiveNo: Number,
    level: Number,
  },
  data() {
    return {};
  },
  methods: {
    chooseHandler() {
      this.$emit('choose');
    }
  }
};
</script>

<style lang="scss" >
// @import '@/assets/css/components/ibcard.scss';
</style>
