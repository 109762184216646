<template>
  <div class="ib_level_list">
    <div v-if="!show" class="more" @click="showList" data-testid="showMore">
    </div>
    <div v-else class="level_content">
      <div class="arrow arrow_left" @click="getPrevPageArr" data-testid="arrowLeft" v-if="ibList.length > sizeFlag">
      </div>
      <div class="level_card_list">
        <div v-for="item in chunkedList[curPage]" :key="item.id" class="level_card">
          <ibCard :name="item.name" :accNumber="item.account" :isActiveNo="isActiveNo" :level="level"
            @choose="chooseIB(item, level)" :style="levelColor"></ibCard>
        </div>
      </div>
      <div class="arrow  arrow_right" @click="getNextPageArr" data-testid="arrowRight" v-if="ibList.length > sizeFlag">
      </div>
    </div>
  </div>
</template>

<script>
import ibCard from './ibCard';

export default {
  name: 'levelList',
  components: {
    ibCard: ibCard
  },
  props: {
    level: Number,
    ibList: Array
  },
  data() {
    return {
      hasNext: true,
      hasPrev: false,
      curPage: 0,
      chunkedList: [],
      size: 3,
      isActiveNo: null,
      show: false,
      bodyDirection: null,
      colorList: ['#599DF8', '#6573F2', '#8D5DF1', '#CB5DF1', '#F15D9B', '#F15D66', '#F1A45D']
    };
  },
  computed: {
    hasNextFlag() {
      return this.curPage < this.chunkedList.length - 1;
    },
    hasPrevFlag() {
      return this.curPage !== 0;
    },
    sizeFlag() {
      const matchesMobile = window.matchMedia('(max-width: 767px)').matches;
      const sizes = {
        matchesMobile: 1
      };
      return matchesMobile ? sizes['matchesMobile'] : this.size;
    },
    levelColor() {
      if (this.level > this.colorList.length) {
        return { '--color': this.colorList[this.level % this.colorList.length - 1] }
      } else {
        return { '--color': this.colorList[this.level - 1] }
      }
    }
  },
  watch: {
    ibList: {
      immediate: true,
      handler(val) {
        this.curPage = 0;
        //if items more than 24, el-col has 2 lines (8x2). 8 per line.
        //if (this.ibList.length >= 24) this.size = 16;
        this.chunkedList = this.chunk(val, this.sizeFlag);
        if (this.level === 1 && this.chunkedList[0] && this.chunkedList[0][0] && this.chunkedList[0][0].account) {
          this.isActiveNo = this.chunkedList[0][0].account;
          this.$emit('curAccNumberCallback', { isActiveNo: this.isActiveNo, isActiveLevel: this.level });
        }
        if (this.level === 1) {
          this.show = true;
        }
      }
    }
  },
  methods: {
    showList() {
      this.show = !this.show;
    },
    chunk(arr, size) {
      return arr.reduce((acc, _, i) => (i % size ? acc : [...acc, arr.slice(i, i + size)]), []);
    },
    getNextPageArr() {
      if (this.hasNextFlag) {
        this.curPage++;
      }
    },
    getPrevPageArr() {
      if (this.hasPrevFlag) {
        this.curPage--;
      }
    },
    chooseIB(item, level) {
      this.isActiveNo = item.account;
      this.$emit('curAccNumberCallback', { isActiveNo: this.isActiveNo, isActiveLevel: this.level });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.bodyDirection = document.body.dir;
    });
  }
};
</script>

<style lang="scss">
// @import '@/assets/css/components/iblevel.scss';
.ib_level_list {
  .more {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: url('~@/assets/images-1/ibLevel/add.png') no-repeat;
    background-size: 100%;
    @include rtl-sass-prop(margin-left, margin-right, 50%);
    @include rtl-sass-prop-dual(transform, translateX(-50%), transform, translateX(50%));
    cursor: pointer;

    &:hover {
      background: url('~@/assets/images-1/ibLevel/add_active.png') no-repeat;
      background-size: 100%;
    }
  }

  .level_content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;

    .arrow {
      cursor: pointer;
      width: 32px;
      height: 32px;
      border-radius: 50%;

      &.arrow_left {
        @include rtl-sass-prop-dual(background, url('~@/assets/images-1/ibLevel/left.png') no-repeat, background, url('~@/assets/images-1/ibLevel/right.png') no-repeat);
        @include rtl-sass-prop-dual(background-size, 100%, background-size, 100%);

        &:hover {
          @include rtl-sass-prop-dual(background, url('~@/assets/images-1/ibLevel/left_active.png') no-repeat, background, url('~@/assets/images-1/ibLevel/right_active.png') no-repeat);
          @include rtl-sass-prop-dual(background-size, 100%, background-size, 100%);
        }
      }

      &.arrow_right {
        @include rtl-sass-prop-dual(background, url('~@/assets/images-1/ibLevel/right.png') no-repeat, background, url('~@/assets/images-1/ibLevel/left.png') no-repeat);
        @include rtl-sass-prop-dual(background-size, 100%, background-size, 100%);

        &:hover {
          @include rtl-sass-prop-dual(background, url('~@/assets/images-1/ibLevel/right_active.png') no-repeat, background, url('~@/assets/images-1/ibLevel/left_active.png') no-repeat);
          @include rtl-sass-prop-dual(background-size, 100%, background-size, 100%);
        }
      }
    }

    .level_card_list {
      display: flex;

      .level_card {
        margin: 0 12px;
        padding: 0;
        height: inherit;

        .level_item {

          .level_top {
            span:nth-child(1) {
              background: var(--color);
            }
          }

          .level_bottom {

            span {
              background: var(--color);
            }
          }
        }
      }
    }
  }
}
</style>
