<template>
  <PageWrapper>
    <PagePanel>
      <PageSearchArea buttonTestId="UPDATE" :showButton="false">
        <template #form>
          <el-form label-position="top">
            <AccountNumber ref="accountNumber" @accountCallback="handleMainAccSelect"></AccountNumber>
          </el-form>
        </template>
      </PageSearchArea>
    </PagePanel>
    <div class="ib_level">
      <div class="level_item level_item_ib">
        <div class="level_top">
          <span>{{ $t('multiib.level', { level: 1 }) }}</span>
          <span>{{ accountID }}</span>
        </div>
        <div class="level_bottom">
          <span>
            <svg-icon className="level_icon" iconClass="level"></svg-icon>
          </span>
          <p class="account_name">{{ ibName }}</p>
        </div>
      </div>
      <div v-for="(item, index) in items" :key="item.account">
        <levelList :ibList="item" :level="index + 1" @curAccNumberCallback="setCurAccNumber"></levelList>
      </div>
      <div v-if="!this.items.length && !isActiveLevel" class="no_ib">
        <p>{{ $t('multiib.noib') }}</p>
      </div>
    </div>
  </PageWrapper>
</template>

<script>
import AccountNumber from '@/components/customUI/AccountNumber';
import levelList from './components/levelList';
import { apiIbAccounts } from '@/resource';

export default {
  name: 'IbLevel',
  components: {
    levelList: levelList,
    AccountNumber
  },
  data() {
    return {
      userId: this.$store.state.common.CUID,
      ibName: this.$store.state.common.userName,
      items: [],
      itemsDetails: {},
      isActiveNo: '',
      isActiveNoItems: [],
      isActiveLevel: null,
      levels: []
    };
  },
  methods: {
    handleMainAccSelect() {
      this.itemsDetails = {};
      this.isActiveLevel = null;
      this.isActiveNo = this.accountID;
    },
    setCurAccNumber(value) {
      this.isActiveNo = value['isActiveNo'];
      this.isActiveLevel = value['isActiveLevel'];
    },
    getCurAccNoItems(value) {
      apiIbAccounts('sub_ibs', {
        userId: this.userId,
        account: parseInt(value)
      }).then(resp => {
        if (resp.data.code === 0 && resp.data.data.length > 0) {
          this.isActiveNoItems = resp.data.data
            .sort((a, b) => a.account - b.account)
            .sort((a, b) => a.name.localeCompare(b.name));
        } else {
          this.isActiveNoItems = [];
        }
      });
    }
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
  },
  watch: {
    isActiveNo(val) {
      this.getCurAccNoItems(val);
    },
    isActiveNoItems: {
      handler(val) {
        this.items = [];
        if (!this.isActiveLevel) this.isActiveLevel = 0;
        let level = parseInt(this.isActiveLevel) + 1; //make index 0 to level 1

        for (let [key, value] of Object.entries(this.itemsDetails)) {
          if (this.isActiveLevel < parseInt(key)) delete this.itemsDetails[key];
        }

        if (this.isActiveNoItems.length !== 0) {
          this.itemsDetails[level] = this.isActiveNoItems;
        }
        this.items = Object.values(this.itemsDetails);
      }
    },
    items: {
      handler(val) {
        this.levels = Array.from({ length: parseInt(this.items.length) + 1 }, (v, k) => k + 1);
      }
    }
  }
};
</script>

<style lang="scss">
// @import '@/assets/css/pages/iblevel.scss';
.ib_level {
  margin-top: 50px;

  .level_item {
    height: 100%;
    background: #FBFBFB;
    box-shadow: 0px 12px 24px rgba(10, 10, 31, 0.04);
    border-radius: 10px;
    padding: 16px 10px 10px 10px;
    width: 226px;
    cursor: pointer;
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;

    &.level_item_active {
      border: 1px solid $primary;
    }

    .level_top {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      span:nth-child(1) {
        display: inline-block;
        font-size: 12px;
        color: $white;
        line-height: 18px;
        background: $primary;
        border-radius: 4px;
        padding: 3px 6px;
      }

      span:nth-child(2) {
        font-size: 12px;
        color: #6e6e73;
        line-height: 18px;
        @include rtl-sass-prop(margin-left, margin-right, 8px);
      }
    }

    .level_bottom {
      padding: 16px;
      background: $white;
      border-radius: 10px;
      text-align: center;
      height: inherit;

      span {
        display: inline-block;
        background: $primary;
        padding: 10px;
        border-radius: 50%;
        margin-bottom: 6px;

        .svg-icon {
          font-size: 30px;
        }
      }

      .account_name {
        font-size: 16px;
        color: $black;
        line-height: 24px;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &.level_item_ib {
      @include rtl-sass-prop(margin-left, margin-right, 50%);
      @include rtl-sass-prop-dual(transform, translateX(-50%), transform, translateX(50%));
      margin-bottom: 34px;

      .level_bottom {
        span {
          .svg-icon {
            font-size: 36px;
          }
        }

        .account_name {
          font-size: 18px;
          line-height: 28px;
          font-weight: 500;
        }
      }
    }
  }

  .no_ib {
    p {
      font-size: 14px;
      color: $text-secondary;
      text-align: center;
      margin: 24px 0;
    }
  }
}
</style>
